export const Com ={
 titre:'Stratégie  de communication',
 texte:"Nous posons un diagnostic expert sur vos enjeux de communication et définissons un plan d’actions adapté pour faire connaître votre entreprise, rendre votre marque séduisante et attirer les clients."

}
export const Ident ={
    titre:'identité de marque',
    texte:"De la recherche d’identité à la création d’un logo, nous concevons et développons l’image de votre entreprise en fonction des codes liés à votre secteur d’activité, mais aussi de votre positionnement et de vos valeurs."
   
}
export const Crea ={
 titre:'Création graphique',
 texte:"Carte de visite, plaquette, flyer, affiche, support publicitaire, signalétique, packaging : nous réalisons des outils et des supports de communication pertinents pour atteindre vos objectifs de communication."

}

export const Site ={
    titre:'Site web et référencement',
    texte:"De la stratégie digitale à la conception graphique et technique, nous réalisons des sites internet vitrines et marchands bien référencés pour les entreprises exigeantes qui cherchent à être visibles sur le net et développer leur activité."
   
}

export const Dig ={
 titre:'Communication digital',
 texte:"Nous gérons l’animation de vos réseaux sociaux. Notre expertise s’étend de la stratégie éditoriale, à la création de publications originales et engageantes en passant par le suivi au quotidien de l’activité et l’analyse statistiques."
}

export const App ={
    titre:'Application Mobile',
    texte:"Des réponses innovantes à des problématiques quotidiennes par des applications performantes & cross plateforme. "
   
}
export const Print ={
 titre:'Impression tous support',
 texte:"Avec notre Bureau d’Etudes intégré, la conception nous permet d’être actif sur l’ensemble du marché des supports publicitaires Indoor ou Outdoor. Il permet de répondre à vos problématiques les plus complexes."

}

export const Design ={
    titre:'Design produits',
    texte:"Nous nous appuyons sur des compétences transversales pour designer des produits vendeurs, prêts à être fabriqués et aux prix visés."
   
}

export const BD ={
 titre:'Bande dessinée',
 texte:"Des réponses innovantes à des problématiques quotidiennes par des applications performantes & cross plateforme. "
}

