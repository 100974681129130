import React from 'react'
import './Menu.css'
import { NavLink } from 'react-router-dom'

import Confirmation from './Composant_Menu/Confirmation/Confirmation'
import Devis from './Composant_Menu/Devis/Devis'
import SignIn from './Composant_Menu/SignIn/SignIn'


const Menu = ({onclickTeleport}) => {

 
 const revealDevis=(e)=>{
   
  
    if (e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.contains('MenuRightDivhide') ) {

      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.replace('MenuRightDivhide', 'MenuRightDivreveal')

      e.currentTarget.setAttribute('style', 'font-weight:900;color:#E8C133')

      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.classList.toggle('derriere')
      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.classList.toggle('devant')
      

      //e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[1].classList.replace('MenuRightDivreveal','MenuRightDivhide' )
      
    }
    else {
      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.replace('MenuRightDivreveal','MenuRightDivhide' )

      e.currentTarget.setAttribute('style', 'font-weight:600; color:#fff')

      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.classList.toggle('derriere')
      
      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.classList.toggle('devant')

    }

   
}

/*
  const revealConnexion=(e)=>{
    if (e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.contains('MenuRightDivhide')) {

      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.replace('MenuRightDivhide','MenuRightDivreveal')

      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[1].classList.replace('MenuRightDivreveal','MenuRightDivhide' )

      
    }
    else {
      e.currentTarget.parentNode.parentNode.parentNode.nextSibling.childNodes[0].childNodes[0].classList.replace('MenuRightDivreveal','MenuRightDivhide' )

    }
   
   
}
*/
  return (
    <>
  
     <div id='Menu' className={'hide'}>

        <Confirmation/>
     
        <div className='MenuLeftConteneur'>
          <div id='MenuLeftDIv'>
          
            <ul onClick={onclickTeleport}>
              <li>
                <NavLink to='./Services' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink to='./Market' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                  Market
                </NavLink>
              </li>
              <li>
                <NavLink to='./GraphiquePrint' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                  Graphique & Print
                </NavLink>
              </li>
              <li>
                <NavLink to='./WebDev' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                  Web Dev
                </NavLink>
              </li>
              <li>
                <NavLink to='./AppDev' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                  App Dev
                </NavLink>
              </li>
              {/*<li>
                <NavLink to='./DesignProduit' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                 Design Produits
                </NavLink>
              </li>
              <li>
                <NavLink to='./Animation' style={({isActive})=>{return isActive?{color: '#E8C133', fontSize:'1.2rem',fontWeight: 700,transition: '0.3s'}:{}}}>
                 Animation 2D/3D
                </NavLink>
  </li>*/}
            </ul>
            
            <ul id='secondebloc'>
              <li  className='devieur' onClick={revealDevis}>Demandez un devis</li>
              {/*<li onClick={revealConnexion}>Connexion</li>*/}
            </ul>
          
          </div>
        </div>

        <div className="MenuRightConteneur derriere">
          <div id='MenuRight'>
            
            <Devis/>  

           { /*<SignIn/>*/}
            

          </div>
        </div>

      


     </div>
    </>
   
  )
}

export default Menu