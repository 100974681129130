import React from 'react'
import './Services.css'
import Main_colore from '../../assets/img/Main_colore.png'
import ServicesCard from '../../composants/ServicesCard/ServicesCard'
import Levelamain from '../../assets/img/levelamain.png'
import BesoinApp from '../../assets/img/BesoinApp.svg'
import  {App, BD, Com, Crea, Design, Dig, Ident, Print, Site} from '../../Data/datatexte'
import { App_ico, BD_ico, Com_ico, Crea_ico, Design_ico, Dig_ico, Ident_ico, Print_ico, Site_ico } from '../../Data/dataimage'


const Services = () => {

  const Contactshow=(e)=>{
    window.location.href='tel:+242 06 905 97 97'
    
  }

  return (
    
    <>
    <main>
      <section id='Servicessection1' className='container'>
        <div id='serv'>services</div>

        <div id='blocun'>
          
          <div>
            <h1 className='heading1'>Des idées Créatives pour vos projets</h1>
            <p>
            Une stratégie bien pensée et une maîtrise des métiers de la communication sont les deux éléments indispensables au succès de votre entreprise sur le long terme.
            </p>
            <p>
            Notre agence dispense un accompagnement stratégique et opérationnel qui combine conseil, création, innovation et bon sens.
            </p>
            <p>
            Chaque projet que nous réalisons est intégré dans une démarche de communication globale qui répond aux besoins réels de l’entreprise.
            </p>
          </div>
          <div>
            <img src={Main_colore} alt="main colore" />
          </div>
        </div>
      </section>
      <section id="Servicessection2" className='container'>
        <ServicesCard icon={Com_ico} titre={Com.titre} texte={Com.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Ident_ico} titre={Ident.titre} texte={Ident.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Crea_ico} titre={Crea.titre} texte={Crea.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Site_ico} titre={Site.titre} texte={Site.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Dig_ico} titre={Dig.titre} texte={Dig.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={App_ico} titre={App.titre} texte={App.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Print_ico} titre={Print.titre} texte={Print.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={Design_ico} titre={Design.titre} texte={Design.texte} /*style={{width:'200px'}}*/></ServicesCard>
        <ServicesCard icon={BD_ico} titre={BD.titre} texte={BD.texte} /*style={{width:'200px'}}*/></ServicesCard>
      </section>
      <section id="Servicessection3" >
        <div id='divSS3' className='container'>
          <div className='bloc'>
            <h1 className='heading1'>
            Formations <br />et coachings
            </h1>
            <p>
            Nous concevons et animons des formations sur la transformation digitale et l’utilisation professionnelle des réseaux sociaux qui constituent un levier d’innovation et de croissance. Nous avons développé une méthode adaptée pour former les dirigeants et salariés qui souhaitent gérer et maîtriser eux-mêmes leur communication digitale ou qui ont envie de s’investir dans l’animation et la modération des comptes réseaux sociaux de leur entreprise (Facebook, Twitter, Linkedin, Instagram…). 
            </p>
          </div>
          <div className='bloc'>
            <img src={Levelamain} alt="Leve la main" />
          </div>
        </div>
      </section>
      <section id='Servicessection4'>
        <div className="container">
          <div>
            <h1 className='heading1'>Besoin <br />d’une Appli ?</h1>
            <p>Contactez-notre équipe pour échanger sur votre projet, vos besoins et vos attentes.</p>
            <button className='DevisButton' onClick={Contactshow}>Voir nos produits</button>
          </div>
          <div>
            <img src={BesoinApp} alt="" />
          </div>
        </div>
      </section>

    </main>
      
    </>
  )
}

export default Services