import React from 'react'
import './Membre.css'


const Membre = ( {Profil,ProfilPic,NomPrenom,fonction}) => {
  return (
    <>
    <div id='Membre'>
        <div style={{position:'relative'}}> 
            <img src={ProfilPic} alt="profil" />
            <img src={Profil} alt="Profil_Perso" style={{position:'absolute'}}/>
        </div>
        <span>{NomPrenom}</span>
        <div>{fonction}</div>
    </div>
    </>
  )
}

export default Membre