import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';

import Footer from './composants/Footer/Footer';
import Navbar from './composants/Navbar/Navbar';

import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Market from './pages/Market/Market';
import GraphiquePrint from './pages/GraphiquePrint/GraphiquePrint';
import WebDev from './pages/WebDev/WebDev';
import AppDev from './pages/AppDev/AppDev';
import DesignProduit from './pages/DesignProduit/DesignProduit'
import Animation from './pages/Animation/Animation';



import EnTravaux from './pages/EnTravaux/EnTravaux';
import Sandbox from './pages/Sandbox/Sandbox';
import ErrorPage from './pages/ErrorPage/ErrorPage';


function App() {
  return (
      <>
        <BrowserRouter>

            <Navbar/>         

            <Routes> 
              
              <Route path='/Sandbox' element={<Sandbox/>}/>
              <Route path='/ErrorPage' element={<ErrorPage/>}/>

              <Route path='/EnTravaux' element={<EnTravaux/>}/>
              
              <Route path='/' element={<Home/>}/>
              <Route path='/Services' element={<Services/>}/>
              <Route path='/Market' element={<Market/>}/>
              <Route path='/GraphiquePrint' element={<GraphiquePrint/>}/>
              <Route path='/WebDev' element={<WebDev/>}/>
              <Route path='/AppDev' element={<AppDev/>}/>
              <Route path='/DesignProduit' element={<EnTravaux/>}/>
              <Route path='/Animation' element={<EnTravaux/>}/>

            </Routes>

            <Footer/>

        </BrowserRouter>
    </>

  
  )
}

export default App;
