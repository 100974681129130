

//Regex defintion
export const Regex_Nom= new RegExp(/^[a-z A-Z]{3,10}?$/)
export const Regex_Tel= new RegExp(/^\++[0-9]{6,14}?$/)
export const Regex_Mail= new RegExp(/^[a-z A-Z 0-9.-_]+@[a-zA-Z 0-9.-_]+\.[a-z]{2,6}?$/)

export const erreurs={ nom_erreur:'Le champ Nom est mal rempli',
                numero_erreur:' Le champ Tél. est mal rempli ',
                mail_erreur : 'Le champ mail est mal rempli',
                message_erreur:' Le message ne peut-être vide'
}
export const tab_erreurs=['Le champ Nom est mal rempli',' Le champ Phone est mal rempli ','Le champ mail est mal rempli',' Le message ne peut-être vide']

