import React from 'react'
import './Entravaux.css'
import Entravaux from '../../assets/img/Entravaux.png'

const EnTravaux = () => {
  return (
    <div id='entravaux'> 
        <img src={Entravaux} alt="En travaux"/>
    </div>
  )
}

export default EnTravaux