import React from 'react'
import { useState } from 'react'

import './CardDepliable.css'

import card_btn from '../../assets/icons_btn/Arrowbtn.png'




export const Des_p ='Nous imaginons et concevons ensemble votre produit digital pour maximiser sa valeur tout en restant focus utilisateur !'
export const Dev_p ='Nous réinventons la collaboration en devenant votre équipe tech pour vous accompagner et vous conseiller dans le cadrage technique et le développement agile de votre produit digital !'

export const Product_p ='Pour assurer le succès de votre produit digital nous vous accompagnons dans la mise en place d’une stratégie gagnante !'


const CardDepliable = ({CardDepliable_titre,icon_card,paragraph_text}) => {

    const [toggled,setToggle]=useState('nonToggledClass') 
    const [paragraph,setparagraph]=useState('nonparagraphClass') 
    const [vide,setVide]=useState(false)
  
   const onclick_btn = function (e) {
      

      if (toggled==="nonToggledClass") {
  
        setToggle('ToggledClass')
        setparagraph('paragraphClass')
        setVide(true)
  
      }
      else {
  
        setToggle('nonToggledClass')
        setparagraph('nonparagraphClass')
        setVide(false)
  
      }
  
  
   }
  
  return (
    <div className='Depliable'>

      <div className='heading'>
        <div className='titre'>

          <img src={icon_card} alt=""/> 

          <span> {CardDepliable_titre} </span> 

        </div>

        <div onClick={onclick_btn} className={toggled}>
            <img src={card_btn} alt="bouton depliant" />
        </div>

      </div>
      
      <div className={paragraph}>
        <p>

          {vide?paragraph_text:''}
      

        </p>
      </div>

    </div>
  )
}

export default CardDepliable

