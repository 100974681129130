import React from 'react'
import './Confirmation.css'


const Confirmation = (props) => {

  return (
    <>
        <div id='boite' className='hideconfirmation'>
            
            <span>Merci ! Votre message a bien été envoyé</span>

            <button onClick={e=>e.currentTarget.parentNode.classList.toggle('hideconfirmation')}>OK</button>

        </div>
    </>
    
  )
}

export default Confirmation