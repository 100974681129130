import React from 'react'
import './ErrorPage.css'
import  ERROR from '../../assets/img/Entravaux.png'

const ErrorPage = () => {
  return (
    <div id='errorpage'>
        <img src={ERROR} alt="404" />
        <span>Page non trouvée </span>
    </div>
  )
}

export default ErrorPage