import React, { useState } from 'react'
import Devis from '../../composants/Menu/Composant_Menu/Devis/Devis'
import SignOut from '../../composants/Menu/Composant_Menu/SignOut/SignOut'
import SignUp from '../../composants/Menu/Composant_Menu/SignUp/SignUp'
import SIgnIn from '../../composants/Menu/Composant_Menu/SignIn/SignIn'
import ResetPassword from '../../composants/Menu/Composant_Menu/ResetPassword/ResetPassword'
import Contacts_Popup from '../../composants/Contacts/Contacts_Popup/Contacts_Popup'

import icon_card from '../../assets/icons_btn/design_puce.png'
import card_btn from '../../assets/icons_btn/Arrowbtn.png'



export const DemandeDevis = ({onclick}) => {


  return (
    <div onClick={onclick} id='DevisButton'>Demande un devis</div>
  )
}

const Depliable={ 
  backgroundColor :'#222121',
  padding :'2rem',
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  justifyContent:'space-between',


  width:'50rem',
  borderRadius:'1rem',
  color:'#E7C133',
 


}
const heading={
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',

  gap:'2rem',
  width:'100%',
  fontSize:'2rem',
  fontWeight:'900'
}
const titre={
  display:'flex',
  alignItems:'center',
  justifyContent:'center',

  gap:'2rem'
}




export const CardDepliable = ({CardDepliable_titre}) => {



  const [toggled,setToggle]=useState('nonToggledClass') 
  const [paragraph,setparagraph]=useState('nonparagraphClass') 

 const onclick_btn = function () {

 
    if (toggled==="nonToggledClass") {

      setToggle('ToggledClass')
      setparagraph('paragraphClass')

    }
    else {

      setToggle('nonToggledClass')
      setparagraph('nonparagraphClass')

    }


 }

 
  return (
    <div style={Depliable}>

      <div style={heading}>
        <div style={titre}>

          <img src={icon_card} alt=""/> 

          <span>{CardDepliable_titre} </span> 

        </div>

        <div onClick={onclick_btn} className={toggled}>
            <img src={card_btn} alt="bouton depliant" />
        </div>

      </div>
      
      <div className={paragraph}>
        <p>
      
        Nous réinventons la collaboration en devenant votre équipe tech pour vous accompagner et vous conseiller dans le cadrage technique et le développement agile de votre produit digital !



        </p>
      </div>

    </div>
  )
}






const Sandbox = () => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'#333',width:'100vw',height:'100vh'}}>
      {/*<Devis/>
      <SignUp/>
      <SIgnIn/>
      <SignOut/>
      <ResetPassword/>
      <Contacts_Popup/>*/}

      <DemandeDevis onclick={e=>{alert('Devis')}}/>
      <CardDepliable CardDepliable_titre={'Design'}/>

      
   
    </div>
  )
}

export default Sandbox