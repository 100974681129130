import React from 'react'
import './Contacts_Popup.css'

import Whatsapp from '../../../assets/icons/whatsapp.svg'
import Facebook from '../../../assets/icons/facebook.svg'
import Linkedin from '../../../assets/icons/linkedin.svg'
import Telephone from '../../../assets/icons/Telephone.svg'

const Contacts_Popup = ({visibility}) => {
  return (
    <>
        <div id='Contact_Popup' className={visibility}>
            <ul>
                <li onClick={e=>window.location.href='https://wa.me/242069059797?text=Bonjour Ornoir, je vous contacte pour...'}>
                    <img src={Whatsapp} alt="Whatsapp icon" /> <span>Whatsapp</span>
                </li>
                <li onClick={e=>window.location.href='https://www.facebook.com/Ornoir.a'}>
                    <img src={Facebook} alt="Facebook icon" /> <span>Facebook</span>
                </li>
                <li onClick={e=>window.location.href='https://www.linkedin.com/company/ornoir-agency'}>
                    <img src={Linkedin} alt="Linkedin icon" /> <span>Linkedin</span>
                </li>
                <li onClick={e=>window.location.href='tel:+242 06 905 97 97'}>
                    <img src={Telephone} alt="Telephone icon" /> <span>+242 06 905 9797</span>
                </li>
            </ul>
        </div>
    </>
  )
}

export default Contacts_Popup