import React from 'react'
import './ServicesCard.css'


const ServicesCard = ({icon,titre,texte,style}) => {
  return (
    
    <>
     <div id='Card' style={style}>
      <div>
        <img src={icon} alt="LogoCard" />
      </div>
      <h1>{titre}
      </h1>
      <p>
      {texte}
      </p>
     </div>
    </>
  )
}

export default ServicesCard