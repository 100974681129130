import React from 'react'
import './PrintCard.css'

const PrintCard = ({src,alt,titre}) => {
  return (
    <>

     <div id='PrintCard'>
        <img src={src} alt={alt}/>
        <span>{titre}</span>
     </div>
    
    </>
    
  )
}

export default PrintCard