import React, {useState,  useRef } from 'react';
import emailjs from '@emailjs/browser'
import './Devis.css'

import close_ico from '../../../../assets/icons/close-circle.svg'

import {Regex_Nom,Regex_Tel,Regex_Mail,tab_erreurs} from '../../../../helpers/Utils'



const Devis = () => {


  const form=useRef()

  const [Message_erreur, setMessage_erreur]=useState('')
  
  const [bool_nom,set_Bool_nom]=useState(false)
  const [bool_tel,set_Bool_tel]=useState(false)
  const [bool_mail,set_Bool_mail]=useState(false)
  const [bool_msg,set_Bool_msg]=useState(false)
 

  const arrayed=[bool_nom,bool_tel,bool_mail,bool_msg]
  const sendable=[true,true,true,true]


  const checker_Nom=(e)=>{
    if (Regex_Nom.test(e.currentTarget.value)) {
    setMessage_erreur('')
    set_Bool_nom(true)
    e.currentTarget.classList.remove('inputfailed')
  } 
  else {
    setMessage_erreur(tab_erreurs[0])
    set_Bool_nom(false)
    e.currentTarget.classList.add('inputfailed')
  }}

  const checker_Tel=(e)=>{if (Regex_Tel.test(e.currentTarget.value)) {
    setMessage_erreur('')
    set_Bool_tel(true)
    e.currentTarget.classList.remove('inputfailed')
  }
   else {
    setMessage_erreur(tab_erreurs[1])
    set_Bool_tel(false)
    e.currentTarget.classList.add('inputfailed')
  }}

  const checker_Mail=(e)=>{if (Regex_Mail.test(e.currentTarget.value)) {
     setMessage_erreur('')
    set_Bool_mail(true)
    e.currentTarget.classList.remove('inputfailed')
  } 
  else {
    setMessage_erreur(tab_erreurs[2])
    set_Bool_mail(false)
    e.currentTarget.classList.add('inputfailed')
  }}

  const checker_Message=(e)=>{if (e.currentTarget.value.length<=0) {
    setMessage_erreur(tab_erreurs[3])
    set_Bool_msg(false)
    e.currentTarget.classList.add('inputfailed')
  } 
  else {
     setMessage_erreur('')
    set_Bool_msg(true)
    e.currentTarget.classList.remove('inputfailed')
  }}

  function reset_bool () {
    set_Bool_nom(false);
    set_Bool_tel(false)
    set_Bool_mail(false)
    set_Bool_msg(false)
   }

  const Envoyer=(e)=>{
  
    if (arrayed.toString()===sendable.toString()) {
      
      emailjs.sendForm('service_fc8o6zl','template_9nswine',form.current,'Z59S7ZKQbLZHXfGaF').then(() => {

        form.current.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].textContent='Merci ! Votre message a bien été envoyé';
     
        form.current.parentNode.parentNode.parentNode.parentNode.childNodes[0].classList.remove('hideconfirmation');
        reset_bool() 

        form.current.reset()
      


    }, () => {

      form.current.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].textContent='Il y\'a eu une erreur pendant l\'envoi, Veuillez réessayer';
      

      form.current.parentNode.parentNode.parentNode.parentNode.childNodes[0].classList.remove('hideconfirmation');
 
    }
    );
    
     
      } 

    else {
      
        if(!(Regex_Nom.test(form.current.childNodes[1].childNodes[0].value))) { form.current.childNodes[1].childNodes[0].classList.add('inputfailed')}

        if(!(Regex_Tel.test(form.current.childNodes[2].childNodes[0].value))) {form.current.childNodes[2].childNodes[0].classList.add('inputfailed')}

        if(!(Regex_Mail.test(form.current.childNodes[3].childNodes[0].value))) { form.current.childNodes[3].childNodes[0].classList.add('inputfailed')}

        if(form.current.childNodes[4].childNodes[0].value.length<=0) {form.current.childNodes[4].childNodes[0].classList.add('inputfailed')}

        e.currentTarget.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].textContent='Le formulaire a été mal rempli';

        e.currentTarget.parentNode.parentNode.parentNode.parentNode.childNodes[0].classList.remove('hideconfirmation')
          
   
        
      }
  
    
  }

  const Handleclose=(e)=>{

   
    e.currentTarget.parentNode.classList.replace('MenuRightDivreveal','MenuRightDivhide' )

  

    e.currentTarget.parentNode.parentNode.parentNode.classList.toggle('devant')

    e.currentTarget.parentNode.parentNode.parentNode.classList.toggle('derriere')

    
  }



  return (
    <>
      <div id="MenuRightDIvDevis" className='MenuRightDivhide'>

          <div id='closer' onClick={Handleclose}><img src={close_ico} alt="Close_ico"  /></div>

        <form ref={form}>
          <span>Demandez un devis</span>

          <div><input type="text" name="user_name" id="Nom" placeholder='Nom' onBlur={checker_Nom} /></div>

          <div><input type="phone" name="user_phone" id="Phone" maxLength={14} placeholder='Phone' onBlur={checker_Tel}/></div>

          <div><input type="email" name="user_email" id="email_devis" placeholder='Email' onBlur={checker_Mail}/></div>

          <div><textarea type="text" name="user_message" id="textarea" rows="4" cols="20" placeholder='Votre besoin en quelques mots' onBlur={checker_Message}/></div>

        </form>

        <button id='Envoyer' onClick={Envoyer}>Envoyer</button>

        <span style={{color:'#fff', fontWeight:'700', marginTop:'1rem'}}>{Message_erreur}</span>

        <span style={{marginTop:'1rem'}}>Plus rapide, plus simple, plus sûr <br />un accompagnement sur mesure </span>

       

      </div>
    </>
  )
}

export default Devis