import Com from '../assets/services_card/Com.png'
import Ident from '../assets/services_card/identite.png'
import Crea from '../assets/services_card/Creation.png'
import Site from '../assets/services_card/Reference.png'
import Dig from '../assets/services_card/Digital.png'
import App from '../assets/services_card/Application.png'
import Print from '../assets/services_card/impression.png'
import Design from '../assets/services_card/Design pro.png'
import BD from '../assets/services_card/BD.png'

import entete_lettre from '../assets/img/Cards_outils/entete.png'
import badge_acces from '../assets/img/Cards_outils/badge.png'
import chemiserabat from '../assets/img/Cards_outils/chemise.png'
import blocnote from '../assets/img/Cards_outils/blocnote.png'
import cartedevisitepaysage from '../assets/img/Cards_outils/paysage.png'
import cartedevisiteportrait from '../assets/img/Cards_outils/portrait.png'
import cartedevisitecarre from '../assets/img/Cards_outils/carre.png'
import cartedevisiterectangle from '../assets/img/Cards_outils/rect.png'
import polotshirt from '../assets/img/Cards_outils/polo.png'
import kakemono from '../assets/img/Cards_outils/kakemono.png'
import affiche from '../assets/img/Cards_outils/affiche.png'
import depliant from '../assets/img/Cards_outils/Depliant.png'
import Flyers from '../assets/img/Cards_outils/Flyers.png'



export const Com_ico=Com;
export  const Ident_ico=Ident;
export const Crea_ico=Crea
export const Site_ico=Site
export const Dig_ico=Dig
export const App_ico=App
export const Print_ico=Print
export const Design_ico=Design
export const BD_ico=BD

export const PrintCarddata={entete_lettre,badge_acces,chemiserabat,blocnote,cartedevisitepaysage,cartedevisiteportrait,cartedevisitecarre,cartedevisiterectangle,polotshirt,kakemono,affiche,depliant,Flyers}

