import React from 'react'
import './IconMenu.css'

const IconMenu = () => {
  return (
    <>
        <svg  id='IconMenu' width="50" height="28" viewBox="0 0 50 28" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path  d="M35.6274 0H13.5136C6.11173 0 0.111328 6.05622 0.111328 13.527C0.111328 20.9977 6.11173 27.0539 13.5136 27.0539H35.6274C43.0293 27.0539 49.0297 20.9977 49.0297 13.527C49.0297 6.05622 43.0293 0 35.6274 0Z" fill="#E8C133"/>
            <path  d="M34.9563 8.79248H18.2035C17.4633 8.79248 16.8633 9.39802 16.8633 10.145C16.8633 10.892 17.4633 11.4975 18.2035 11.4975H34.9563C35.6965 11.4975 36.2966 10.892 36.2966 10.145C36.2966 9.39802 35.6965 8.79248 34.9563 8.79248Z" fill="black"/>

            <path  d="M25.5749 16.2324H14.183C13.4428 16.2324 12.8428 16.838 12.8428 17.5849C12.8428 18.3319 13.4428 18.9375 14.183 18.9375H25.5749C26.3151 18.9375 26.9152 18.3319 26.9152 17.5849C26.9152 16.838 26.3151 16.2324 25.5749 16.2324Z" fill="black"/>

        </svg>
    </>
  )
}

export default IconMenu;