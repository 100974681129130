import React, {useState } from 'react'
import { NavLink} from 'react-router-dom'
import './Navbar.css'

import Menu from '../Menu/Menu'
import IconMenu from './IconMenu/IconMenu'

import logo from '../../assets/logo/logo-header.svg'
import contact from '../../assets/icons/icon-contact.svg'
import ContactsPopup from '../Contacts/Contacts_Popup/Contacts_Popup'









const Navbar = () => {



  function topAnchor () {
   window.scrollTo({top:0,left:0,behavior:'smooth'})
}


  const [mask,setMask]=useState('')
  const [Visibility,setVisibility]=useState('Notvisible')

  const Menushow=(e)=>{

  
    e.currentTarget.childNodes[0].childNodes[1].classList.toggle('barreTop');
    e.currentTarget.childNodes[0].childNodes[2].classList.toggle('barreBottom');
    
    e.currentTarget.parentNode.nextSibling.classList.toggle('show')

    if(mask==='none') {
      setMask('block')
  
    }
    else {
      setMask('none')

    }
    
  }

  const Contactshow=(e)=>{
   // window.location.href='tel:+242 06 905 97 97'
   if (Visibility==='visible') {
    setVisibility('Notvisible')
    
   } else {
    setVisibility('visible')
    
   }
    
  }

  const onclickTeleport=(e)=> {
    
   e.currentTarget.parentNode.parentNode.parentNode.previousSibling.childNodes[0].childNodes[0].childNodes[1].classList.remove('barreTop');

   e.currentTarget.parentNode.parentNode.parentNode.previousSibling.childNodes[0].childNodes[0].childNodes[2].classList.remove('barreBottom');
    
  e.currentTarget.parentNode.parentNode.parentNode.classList.toggle('show');

    if(mask==='none') {
      setMask('block')
    }
    else { 
      setMask('none')
    };
    topAnchor(e)
  }

  return (
    <>
     
      <header>

        <nav className='container'>
          
          <div onClick={Menushow}>

            <IconMenu/>
            <span>Menu</span>

          </div>

          <NavLink to='./'>

            <div className={mask} onClick={topAnchor}><img src={logo} alt="Logo agency" className='Logoagency' /></div>

          </NavLink>

          <div className={mask} onClick={Contactshow}>

            <span>Contacts</span>
            <img src={contact} alt="icons contacts" className='contact'/>
            <ContactsPopup visibility={Visibility}/>

          </div>

        </nav>

        <Menu onclickTeleport={onclickTeleport}/>
        
      </header>
     
    </>
  )
}

export default Navbar